import * as React from 'react';

import { Layout, SEO } from '../components';

function LiveSportPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="Live Sport" />
      <div>
        <iframe
          allowTransparency
          frameBorder="0"
          scrolling="no"
          className="w-[10px] min-w-full max-w-full h-0 min-h-[1300px] max-h-[1600px] my-0 overflow-auto"
          src="https://secure.gameonlivesports.com.au/live/auto/6002/6002.html"
        ></iframe>
      </div>
    </Layout>
  );
}

export { LiveSportPage as default };
